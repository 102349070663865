import React from "react";
import { Box, Typography, Grid, Container } from "@mui/material";
import GroupImg from "../../assets/group.png";
import styled from "@emotion/styled";

const HighlightBox = styled(Box)({
  position: "relative",
  padding: "0 10px",
  borderRadius: "5px",
  display: "inline-block",
  zIndex: 1,
  fontWeight: 100,
  fontFamily: "aria",
  color: "#013180",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#D0F8CE",
    transform: "rotate(-2deg)",
    zIndex: -1,
  },
});

const Section3 = () => {
  return (
    <Container
      maxWidth="xl"
      sx={{
        p: "100px 0",
      }}
    >
      <Grid container sx={{ height: "70vh", padding: "0 20px" }}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            alignItems: "center",
            paddingLeft: "20px",
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: {
                xs: "none",
                md: "absolute",
              },
              top: "0%",
              left: "10%",
              zIndex: 1,
              width: "100%",
            }}
          >
            <Typography
              variant="h3"
              sx={{
                fontWeight: "bold",
                color: "black",
                marginBottom: "10px",
                zIndex: 1,
              }}
            >
              <HighlightBox component="span">Who we are</HighlightBox>
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: "1.15rem",
                color: "black",
                lineHeight: "1.5",
                zIndex: 1,
                fontWeight: 100,
                color: "#000000",
                mt: 4,
              }}
            >
              Banxity Group is a diversified investment
              and incubation firm, focused on driving 
              growth and creating value across industries. 
              With a team of seasoned experts, 
              we curate and support companies that innovate, adapt, and 
              build enduring value. We are committed to success 
              through resilience and sustainability, with a strategic 
              emphasis on ethical leadership and transformative growth
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              backgroundImage: `url(${GroupImg})`,
              backgroundSize: "cover",
              backgroundPosition: "center right",
              height: {
                xs: "40vh",
                md: "85%",
              },
              position: {
                xs: "none",
                md: "relative",
              },
              right: "10%",
              mt: {
                xs: "30px",
                md: 0,
              },
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Section3;
