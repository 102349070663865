import React, { useState } from "react";
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  FormGroup,
  styled,
} from "@mui/material";
import { Grade } from "@mui/icons-material";

// Styled components
const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    backgroundColor: "white",
    borderRadius: "4px",
    "& fieldset": {
      borderColor: "transparent",
    },
    "&:hover fieldset": {
      borderColor: "transparent",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#4ade80",
    },
  },
});

const StyledCheckbox = styled(Checkbox)({
  color: "#4ade80",
  "&.Mui-checked": {
    color: "#4ade80",
  },
});

export default function Section10() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    services: {
      digitalBanking: false,
      carbonCredits: false,
      farm: false,
      climate: false,
      strategy: false,
      other: false,
    },
  });

  const handleChange = (event) => {
    const { name, value, checked } = event.target;
    if (name.startsWith("service-")) {
      const serviceName = name.replace("service-", "");
      setFormData((prev) => ({
        ...prev,
        services: {
          ...prev.services,
          [serviceName]: checked,
        },
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formData);
  };

  const countryOptions = [
    { value: "US", label: "US" },
    { value: "UK", label: "UK" },
    { value: "UAE", label: "UAE" },
  ];

  return (
    <Box>
      <Box
        sx={{
          bgcolor: "#1e3a8a",
          p: {
            xs: "50px 10px",
            md: "50px 50px",
          },
        }}
      >
        <Container maxWidth="xl">
          <Grid container spacing={4}>
            {/* Left Column */}
            <Grid item xs={12} md={8}>
              <Grid container>
                <Grid item md={6}>
                  <Typography
                    variant="h4"
                    sx={{
                      color: "#4ade80",
                      mb: 2,
                    }}
                  >
                    Connect with Us
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "white",
                      mb: 4,
                      fontWeight: 100,
                    }}
                  >
                    You can reach us anytime via contact@banxity.com
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Box>
                    {/* Name Field */}
                    <Typography
                      sx={{ color: "#4ade80", mb: 1, fontSize: "0.7rem" }}
                    >
                      Name
                    </Typography>
                    <StyledTextField
                      fullWidth
                      name="name"
                      placeholder="Your name"
                      value={formData.name}
                      onChange={handleChange}
                      sx={{
                        height: "50px",
                        "& .MuiOutlinedInput-root": {
                          height: "30px",
                        },
                      }}
                    />

                    {/* Email Field */}
                    <Typography
                      sx={{ color: "#4ade80", mb: 1, fontSize: "0.7rem" }}
                    >
                      Email
                    </Typography>
                    <StyledTextField
                      fullWidth
                      name="email"
                      placeholder="hello@banxity.com"
                      value={formData.email}
                      onChange={handleChange}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          height: "30px",
                        },
                      }}
                    />
                  </Box>
                </Grid>
                <Grid md={12} sx={{}}>
                  <Typography
                    variant="h1"
                    sx={{
                      color: "#1F3A8A",
                      fontSize: { xs: "3rem", md: "10rem" },
                      fontWeight: "bold",
                      lineHeight: 1.1,
                      fontFamily: "aria",
                    }}
                  >
                    .
                  </Typography>
                </Grid>
                <Grid md={12}>
                  <Typography
                    variant="h1"
                    sx={{
                      color: "#14C79A",
                      fontSize: { xs: "3rem", md: "10rem" },
                      fontWeight: "bold",
                      lineHeight: 1.1,
                      fontFamily: "aria",
                      display: {
                        xs: "none",
                        md: "initial",
                      },
                    }}
                  >
                    Get started
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {/* Right Column - Form */}
            <Grid item xs={12} md={4}>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  {/* Phone Field */}
                  <Grid item xs={12}>
                    <Typography
                      sx={{ color: "#4ade80", mb: 1, fontSize: "0.7rem" }}
                    >
                      Phone number
                    </Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <StyledTextField
                          fullWidth
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              height: "30px",
                            },
                          }}
                          name="phone"
                          placeholder="+1 (555) 000-0000"
                          value={formData.phone}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Message Field */}
                  <Grid item xs={12}>
                    <Typography
                      sx={{ color: "#4ade80", mb: 1, fontSize: "0.7rem" }}
                    >
                      How can we help?
                    </Typography>
                    <StyledTextField
                      fullWidth
                      multiline
                      rows={2}
                      name="message"
                      placeholder="Tell us a little about the project..."
                      value={formData.message}
                      onChange={handleChange}
                    />
                  </Grid>

                  {/* Checkboxes */}
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <StyledCheckbox
                                name="service-digitalBanking"
                                checked={formData.services.digitalBanking}
                                onChange={handleChange}
                              />
                            }
                            label={
                              <Typography sx={{ color: "#4ade80" }}>
                                Digital Banking
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            control={
                              <StyledCheckbox
                                name="service-carbonCredits"
                                checked={formData.services.carbonCredits}
                                onChange={handleChange}
                              />
                            }
                            label={
                              <Typography sx={{ color: "#4ade80" }}>
                                Carbon Capture & Exchange
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            control={
                              <StyledCheckbox
                                name="service-farm"
                                checked={formData.services.farm}
                                onChange={handleChange}
                              />
                            }
                            label={
                              <Typography sx={{ color: "#4ade80" }}>
                                Food Security
                              </Typography>
                            }
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <StyledCheckbox
                                name="service-climate"
                                checked={formData.services.climate}
                                onChange={handleChange}
                              />
                            }
                            label={
                              <Typography sx={{ color: "#4ade80" }}>
                                Climate Action
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            control={
                              <StyledCheckbox
                                name="service-strategy"
                                checked={formData.services.strategy}
                                onChange={handleChange}
                              />
                            }
                            label={
                              <Typography sx={{ color: "#4ade80" }}>
                                Investment
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            control={
                              <StyledCheckbox
                                name="service-other"
                                checked={formData.services.other}
                                onChange={handleChange}
                              />
                            }
                            label={
                              <Typography sx={{ color: "#4ade80" }}>
                                Other
                              </Typography>
                            }
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Submit Button */}
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        bgcolor: "#14C79A",
                        color: "#1e3a8a",
                        px: 4,
                        py: 1,
                        textTransform: "capitalize",
                        "&:hover": {
                          bgcolor: "#22c55e",
                        },
                      }}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
