import React from "react";
import { Box, Typography, Grid, Container } from "@mui/material";
import GroupImg from "../../assets/IMG1.png";

const Section5 = () => {
  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          p: {
            xs: "40px 0 0 0",
            md: "0px 50px 50px 20px ",
          },
        }}
      >
        <Grid
          container
          sx={{
            display: {
              xs: "none",
              md: "flex",
            },
            justifyContent: "space-between",
          }}
        >
          <Grid
            item
            xs={5}
            sx={{
              borderTop: "3px solid #14C79A",
            }}
          ></Grid>
          <Grid
            item
            xs={6}
            sx={{
              borderTop: "3px solid #14C79A",
            }}
          ></Grid>
        </Grid>
      </Box>
      <Grid
        container
        sx={{
          height: {
            xs: "100%",
            md: "50vh",
          },
          padding: "0 20px",
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            alignItems: "center",
            paddingLeft: "20px",
            position: {
              xs: "none",
              md: "relative",
            },
            borderLeft: {
              xs: "0px solid #14C79A",
              md: "25px solid #14C79A",
            },
          }}
        >
          <Box
            sx={{
              position: {
                xs: "none",
                md: "relative",
              },
              top: {
                xs: "20%",
                md: "0%",
              },
              left: "40%",
              zIndex: 1,
              width: "90%",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                color: "black",
                marginBottom: "10px",
                zIndex: 1,
                color: "#013180",
                fontSize: "1.2rem",
                fontFamily: "aria",
              }}
            >
              Our Vision
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: "1.35rem",
                color: "black",
                lineHeight: "1.5",
                zIndex: 1,
                fontWeight: 100,
                color: "#000000",
                p: {
                  xs: "0 0px 0 0",
                  md: "0 150px 0 0",
                },
              }}
            >
              To pioneer a sustainable future by developing
               a network of innovative solutions that foster inclusivity,
               long-term value, and growth for farmers, investors, and communities.

            </Typography>
            {/* <Typography
              sx={{
                color: "black",
                margin: {
                  xs: "10px 0 20px 0",
                  md: "10px 0",
                },
                fontWeight: "bold",
              }}
            >
              Know More
            </Typography> */}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              backgroundImage: `url(${GroupImg})`,
              backgroundSize: "cover",
              backgroundPosition: "center right",
              height: {
                xs: "30vh",
                md: "100%",
              },
              position: {
                xs: "none",
                md: "relative",
              },
              right: "4%",
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Section5;
