import React from "react";
import { Box, Typography, Container, Grid } from "@mui/material";
import { styled } from "@mui/system";
import BannerImg from "../../assets/Banner.png";

const HeroText = styled(Typography)({
  color: "white",
  fontWeight: "bold",
  textAlign: "center",
});

const HighlightBox = styled(Box)({
  position: "relative",
  padding: "0 10px",
  borderRadius: "5px",
  display: "inline-block",
  zIndex: 1,
  color: "#0057FF",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#D0F8CE",
    transform: "rotate(-2deg)",
    zIndex: -1,
  },
});

export default function Section1() {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        backgroundImage: `url(${BannerImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {/* Transparent black overlay */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.3)",
          zIndex: 1,
        }}
      />

      <Container
        maxWidth="lg"
        sx={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 2,
          position: "relative",
        }}
      >
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            <HeroText
              variant="h2"
              sx={{
                fontSize: {
                  xs: "2rem",
                  sm: "3rem",
                  md: "4rem",
                },
              }}
            >
              We curate and incubate
            </HeroText>
            <HeroText
              variant="h2"
              sx={{
                fontSize: { xs: "2rem", sm: "3rem", md: "4rem" },
              }}
            >
              <HighlightBox component="span">new-age businesses,</HighlightBox>
            </HeroText>
            <HeroText
              variant="h2"
              sx={{
                fontSize: { xs: "2rem", sm: "3rem", md: "4rem" },
              }}
            >
              guiding them towards growth
            </HeroText>
            <HeroText
              variant="h2"
              sx={{
                fontSize: { xs: "2rem", sm: "3rem", md: "4rem" },
              }}
            >
              and long-term impact.
            </HeroText>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
