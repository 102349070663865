import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import backgroundImage from "../../assets/Hands.png";
import styled from "@emotion/styled";

const HighlightBox = styled(Box)({
  position: "relative",
  padding: "0 10px",
  borderRadius: "5px",
  display: "inline-block",
  zIndex: 1,
  color: "#013180",
  fontFamily: "aria",
  fontWeight: 550,
  whiteSpace: "nowrap",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#ACF3A8",
    transform: "rotate(-2deg)",
    zIndex: -1,
  },
});

const Section8 = () => {
  return (
    <Box sx={{ p: "100px 0" }}>
      <>
        <Grid container spacing={4}>
          {/* Left Grid with Text */}
          <Grid item md={4} xs={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <Typography
                sx={{
                  p: {
                    xs: "0 20px",
                    md: "0px 70px 50px 70px",
                  },
                  fontSize: {
                    xs: "1.8rem",
                    md: "2.5rem",
                  },
                }}
              >
                <HighlightBox component="span"> Careers</HighlightBox>
              </Typography>
            </Box>
          </Grid>

          {/* Right Grid with Background Image and Text */}
          <Grid item md={8} xs={12} sx={{}}>
            <Box>
              <Typography
                variant="h6"
                sx={{
                  color: "black",
                  fontWeight: 100,
                  padding: { xs: "0 20px", md: "0 50px 0 0" },
                }}
              >
                Join us in shaping a sustainable future. Banxity Holding offers
                opportunities to work with cutting-edge projects in finance,
                sustainability, real estate, and agriculture. We are looking for
                driven, passionate individuals who want to make a positive
                impact and contribute to meaningful projects.
              </Typography>
              <Typography
              sx={{
                color: "black",
                margin: {
                  xs: "10px 0 20px 0",
                  md: "10px 0",
                },
                fontWeight: "bold",
              }}
            >
              Exlpore Opportunities
            </Typography>
            </Box>
          </Grid>
        </Grid>
      </>
    </Box>
  );
};

export default Section8;
