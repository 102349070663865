import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import backgroundImage from "../../assets/Hands.png";
import styled from "@emotion/styled";

const HighlightBox = styled(Box)({
  position: "relative",
  padding: "0 10px",
  borderRadius: "5px",
  display: "inline-block",
  zIndex: 1,
  color: "#013180",
  fontFamily: "aria",
  fontWeight: 550,
  whiteSpace: "nowrap",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#ACF3A8",
    transform: "rotate(-2deg)",
    zIndex: -1,
  },
});

const Section8 = () => {
  return (
    <Box
      sx={{
        p: {
          xs: "50px 0 0 0",
          md: "100px 50px",
        },
      }}
    >
      <>
        <Grid
          container
          spacing={4}
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <Grid item md={2} xs={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: "100%",
                borderTop: {
                  xs: 0,
                  md: "3px solid #14C79A",
                },
                borderRight: {
                  xs: 0,
                  md: "3px solid #14C79A",
                },
                borderBottom: {
                  xs: 0,
                  md: "3px solid #14C79A",
                },
              }}
            >
              <Typography
                sx={{
                  p: {
                    xs: "0 0 0 40px",
                    md: 0,
                  },
                  fontSize: {
                    xs: "1.5rem",
                    md: "2rem",
                  },
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <HighlightBox component="span"> Our Team</HighlightBox>
              </Typography>
            </Box>
          </Grid>

          {/* Right Grid with Background Image and Text */}
          <Grid item md={10} xs={12}>
            <Box
              sx={{
                position: "relative",
                height: "60vh",
                backgroundImage: {
                  xs: "none",
                  md: `url(${backgroundImage})`,
                },
                backgroundSize: "cover",
                backgroundPosition: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                padding: 4,
                borderTop: "3px solid #14C79A",
                borderRight: "3px solid #14C79A",
                borderBottom: "3px solid #14C79A",
              }}
            >
              <Typography
                sx={{
                  color: "black",
                  fontWeight: 100,
                  padding: {
                    xs: "0px",
                    md: "0 250px 0 30px",
                  },
                  fontSize: {
                    xs: "0.8rem",
                    md: "1.3rem",
                  },
                }}
              >
                Banxity Holding is driven by an experienced leadership team with a combined background spanning over 80 years across 
                fields such as finance, technology, green development, and strategic investment. Our team brings insights from renowned 
                institutions and multinational corporations, leveraging their expertise to curate, support, and grow businesses that align 
                with our values of sustainability, impact, and resilience. Together, we foster an environment of innovation and 
                collaboration, ensuring each venture is nurtured to reach its fullest potential.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </>
    </Box>
  );
};

export default Section8;
