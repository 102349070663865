import React from 'react'
import Home from "./home/index"
import "./app.css";


const App = () => {
  return (
    <div><Home/></div>
  )
}

export default App