import React from "react";
import { Box, Typography, Grid, Container, Button, Link } from "@mui/material";
import styled from "@emotion/styled";
import InfoIcon from "@mui/icons-material/Info"; // Import Info Icon

import Portfolio1 from "../../assets/Portfolio1.png";
import Portfolio2 from "../../assets/Portfolio2.png";
import Portfolio3 from "../../assets/Portfolio3.png";
import Portfolio4 from "../../assets/Portfolio4.png";
import Portfolio5 from "../../assets/Portfolio5.png";
import Portfolio6 from "../../assets/Portfolio6.png";
import Homeicon from "../../assets/homeicon.png";

const HighlightBox = styled(Box)({
  position: "relative",
  padding: "5px 15px",
  borderRadius: "5px",
  display: "inline-block",
  zIndex: 1,
  color: "#013180",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#ACF3A8",
    transform: "rotate(-2deg)",
    zIndex: -1,
  },
});
const HighlightBox1 = styled(Box)({
  position: "relative",
  padding: "10px 40px 10px 20px",
  borderRadius: "5px",
  display: "inline-block",
  zIndex: 1,
  color: "#013180",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#ACF3A8",
    transform: "rotate(-3deg)",
    zIndex: -1,
  },
});

// Flip box container with perspective for 3D effect
const FlipBox = styled(Box)({
  width: "350px",
  height: "450px",
  border: "1px solid #f1f1f1",
  backgroundColor: "transparent",
  perspective: "1000px", // 3D effect
  borderRadius: "12px",
  overflow: "hidden",
});

// Inner container for handling the flip effect
const FlipBoxInner = styled(Box)({
  position: "relative",
  width: "100%",
  height: "100%",
  textAlign: "center",
  transition: "transform 0.8s",
  transformStyle: "preserve-3d",
  "&:hover": {
    transform: "rotateY(180deg)", // Flip the box horizontally
  },
});

// Front side of the flip box
const FlipBoxFront = styled(Box)({
  position: "absolute",
  width: "100%",
  height: "100%",
  backfaceVisibility: "hidden", // Hide the back when flipped
  color: "black",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

// Back side of the flip box
const FlipBoxBack = styled(Box)({
  position: "absolute",
  width: "100%",
  height: "100%",
  backfaceVisibility: "hidden", // Hide the front when flipped
  color: "white",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column", // Stack the content vertically
  transform: "rotateY(180deg)", // Rotate the back to be initially hidden
  textAlign: "center",
});

// The FlipCard component now accepts props for different content
const FlipCard = ({ image, title, backTitle, backText, link }) => {
  return (
    <FlipBox>
      <FlipBoxInner>
        {/* Front Side */}
        <FlipBoxFront>
          <img
            src={image}
            alt={title}
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </FlipBoxFront>

        {/* Back Side */}
        <FlipBoxBack>
          <div>
            <Box
              sx={{
                p: "20px 0",
              }}
            >
              <img src={Homeicon} height={"50px"} />
            </Box>
            <Box
              sx={{
                border: "1px solid black",
              }}
            ></Box>

            <Typography sx={{ mb: 2, color: "black", mt: 2 }}>
              {backText}
            </Typography>
            <Link href={link} target="_blank">
            <Button
              variant="contained"
              sx={{
                mt: 2,
                backgroundColor: "#266040",
                textTransform: "capitalize",
              }}
              >
              Expolre More
            </Button>
              </Link>
          </div>
        </FlipBoxBack>
      </FlipBoxInner>
    </FlipBox>
  );
};

// SectionWithFlipCards component to render 6 unique cards with different content
const SectionWithFlipCards = () => {
  return (
    <Box
      sx={{
        py: {
          xs: "20px",
          md: "100px",
        },
      }}
    >
      <Container>
        <Typography
          sx={{
            color: "black",
            zIndex: 1,
            fontSize: "2rem",
            fontFamily: "aria",
          }}
        >
          <HighlightBox component="span">Our Portfolio</HighlightBox>
        </Typography>
        <Grid
          container
          spacing={4}
          justifyContent="center"
          sx={{
            mt: {
              xs: "30px",
              md: "20px",
            },
          }}
        >
          {/* Card 1 */}
          <Grid
            item
            md={4}
            xs={12}
            display="flex"
            justifyContent="center"
            sx={{
              position: "relative",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                color: "black",
                marginBottom: "10px",
                zIndex: 1,
                whiteSpace: "nowrap",
                position: "absolute",
                left: "5%",
                top: "20%",
              }}
            >
              <HighlightBox1 component="span">Banxity</HighlightBox1>
            </Typography>
            <FlipCard
              image={Portfolio1}
              title="Paris"
              backTitle="Banxity"
              backText="A farmer-first bank in Brazil focused on empowering agricultural communities through tailored financial services."
              link="https://banxity.com/"
            />
          </Grid>
          {/* Card 2 */}
          <Grid
            item
            md={4}
            xs={12}
            display="flex"
            justifyContent="center"
            sx={{
              position: "relative",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                color: "black",
                marginBottom: "10px",
                zIndex: 1,
                whiteSpace: "nowrap",
                position: "absolute",
                left: "5%",
                top: "20%",
              }}
            >
              <HighlightBox1 component="span">Farmtise</HighlightBox1>
            </Typography>
            <FlipCard
              image={Portfolio2}
              title="London"
              backTitle="Farmtise"
              backText="A dedicated marketplace for perishable commodities, enabling farmers and buyers to connect directly for efficient, fair trade."
              link="https://farmtise.com"
            />
          </Grid>
          {/* Card 3 */}
          <Grid
            item
            md={4}
            xs={12}
            display="flex"
            justifyContent="center"
            sx={{
              position: "relative",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                color: "black",
                marginBottom: "10px",
                zIndex: 1,
                whiteSpace: "nowrap",
                position: "absolute",
                left: "5%",
                top: "20%",
              }}
            >
              <HighlightBox1 component="span">Climevity</HighlightBox1>
            </Typography>
            <FlipCard
              image={Portfolio3}
              title="New York"
              backTitle="Climevity"
              backText="A green asset management platform for trading carbon credits, iRECs, and calculating carbon emissions to help businesses meet sustainability goals."
              link="https://climevity.com"
            />
          </Grid>
          {/* Card 4 */}
          <Grid
            item
            md={4}
            xs={12}
            display="flex"
            justifyContent="center"
            sx={{
              position: "relative",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                color: "black",
                marginBottom: "10px",
                zIndex: 1,
                whiteSpace: "nowrap",
                position: "absolute",
                left: "5%",
                top: "20%",
              }}
            >
              <HighlightBox1 component="span">Novutus</HighlightBox1>
            </Typography>
            <FlipCard
              image={Portfolio4}
              title="Tokyo"
              backTitle="Novutus"
              backText="Facilitates co-ownership of green projects, enabling investors to participate in impactful, sustainable ventures."
              link="https://novutus.com"
            />
          </Grid>
          {/* Card 5 */}
          <Grid
            item
            md={4}
            xs={12}
            display="flex"
            justifyContent="center"
            sx={{
              position: "relative",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                color: "black",
                marginBottom: "10px",
                zIndex: 1,
                whiteSpace: "nowrap",
                position: "absolute",
                left: "5%",
                top: "20%",
              }}
            >
              <HighlightBox1 component="span">Altercarbon</HighlightBox1>
            </Typography>
            <FlipCard
              image={Portfolio5}
              title="Sydney"
              backTitle="Altercarbon"
              backText="Provides sustainable development solutions like solar energy installations, promoting renewable energy adoption."
              link="https://altercarbon.in"
            />
          </Grid>
          {/* Card 6 */}
          <Grid
            item
            md={4}
            xs={12}
            display="flex"
            justifyContent="center"
            sx={{
              position: "relative",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                color: "black",
                marginBottom: "10px",
                zIndex: 1,
                whiteSpace: "nowrap",
                position: "absolute",
                left: "5%",
                top: "20%",
              }}
            >
              <HighlightBox1 component="span">
                Banxity Ecoventures
              </HighlightBox1>
            </Typography>
            <FlipCard
              image={Portfolio6}
              title="Berlin"
              backTitle="Banxity Ecoventures"
              backText="Specializes in real estate projects that integrate sustainable methodologies, contributing to eco-friendly urban development."
              link="https://banxityecoventures.com"
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default SectionWithFlipCards;
