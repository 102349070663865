import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import React from "react";
const HighlightBox = styled(Box)({
  position: "relative",
  padding: "0 10px",
  borderRadius: "5px",
  display: "inline-block",
  zIndex: 1,
  color: "#013180",
  fontFamily: "aria",
  fontWeight: 550,
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#ACF3A8",
    transform: "rotate(-2deg)",
    zIndex: -1,
  },
});
const HighlightBox1 = styled(Box)({
  position: "relative",
  padding: "0 10px",
  borderRadius: "5px",
  display: "inline-block",
  zIndex: 1,
  color: "#013180",
  fontFamily: "aria",
  fontWeight: 550,
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#14C79A",
    transform: "rotate(-2deg)",
    zIndex: -1,
  },
});

const Section4 = () => {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          position: "relative",
          p: {
            xs: "180px 0 10px 0",
            md: "0px 0 100px 0",
          },
        }}
      >
        <Box
          sx={{
            width: {
              xs: "95%",
              md: "95%",
            },
            height: {
              xs: "100%",
              md: "70vh",
            },
            bgcolor: "#14C79A",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <Typography
            sx={{
              position: "absolute",
              top: {
                xs: "0%",
                md: "10%",
              },
              left: "4%",
              fontSize: "2rem",
              display: {
                xs: "none",
                md: "initial",
              },
            }}
          >
            <HighlightBox component="span"> About Us</HighlightBox>
          </Typography>
          <Box
            sx={{
              width: "85%",
              height: {
                xs: "100%",
                md: "50vh",
              },
              bgcolor: "#ACF3A8",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                p: {
                  xs: "20px 25px",
                  md: "0 200px",
                },
                fontWeight: 100,
                fontSize: {
                  xs: "1rem",
                  md: "1.2rem",
                },
                lineHeight: {
                  xs: "none",
                  md: "35px",
                },
                position: "relative",
              }}
            >
              <Typography
                sx={{
                  fontSize: "2rem",
                  pb: "10px",
                }}
              >
                <HighlightBox1
                  sx={{
                    display: {
                      xs: "initial",
                      md: "none",
                    },
                  }}
                >
                  {" "}
                  About Us
                </HighlightBox1>
              </Typography>
              Banxity Group is a dynamic conglomerate committed 
              to creating value and driving sustainable growth 
              in multiple sectors. Our operations span agriculture, 
              real estate, finance, and green assets, where we utilize AI, 
              and smart technologies to deliver scalable, inclusive, 
              and future-proof solutions. By adopting a holistic approach, 
              we are creating an ecosystem that fosters positive outcomes for 
              communities, partners, and the environment, driving meaningful global impact.

            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Section4;
