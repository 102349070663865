import React from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Link,
  Stack,
  IconButton,
} from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import logo from "../../assets/Logo.png";

export default function Footer() {
  return (
    <Box sx={{ bgcolor: "#b9f6ca", pt: 6, pb: 2 }}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          {/* Logo and Address */}
          <Grid item xs={12} md={3}>
            <img
              src={logo}
              alt="Banxity Holding"
              style={{ height: 40, marginBottom: 16 }}
            />
            <Stack spacing={1} sx={{ color: "#333" }}>
              <Box display="flex" alignItems="center" gap={1}>
                {/* <LocationOnIcon fontSize="small" /> */}
                <Typography variant="body2">B13-529</Typography>
              </Box>
              <Typography variant="body2">Business Centre 05</Typography>
              <Typography variant="body2">Rak E2 Business Zone F2</Typography>
              <Typography variant="body2">RAK, United Arab Emirates</Typography>
            </Stack>
          </Grid>

          {/* Company */}
          <Grid item xs={12} sm={6} md={2}>
            <Typography variant="h6" gutterBottom sx={{ color: "#333" }}>
              Company
            </Typography>
            <Stack spacing={1}>
              <Link href="#" underline="none" color="inherit">
                Who We Are
              </Link>
              <Link href="#" underline="none" color="inherit">
                Mission & Vision
              </Link>
            </Stack>
          </Grid>

          {/* Find Out More */}
          <Grid item xs={12} sm={6} md={2}>
            <Typography variant="h6" gutterBottom sx={{ color: "#333" }}>
              Find Out More
            </Typography>
            <Stack spacing={1}>
              <Link href="#" underline="none" color="inherit">
                About Us
              </Link>
              <Link href="#" underline="none" color="inherit">
                Our Portfolio
              </Link>
              <Link href="#" underline="none" color="inherit">
                Team
              </Link>
              {/* <Link href="#" underline="none" color="inherit">
                Career
              </Link>
              <Link href="#" underline="none" color="inherit">
                Contact
              </Link> */}
            </Stack>
          </Grid>

          {/* Opportunities */}
          <Grid item xs={12} sm={6} md={2}>
            <Typography variant="h6" gutterBottom sx={{ color: "#333" }}>
            Opportunities
            </Typography>
            <Stack spacing={1}>
              <Link href="#" underline="none" color="inherit">
                Career
              </Link>
              <Link href="#" underline="none" color="inherit">
                Connect with Us
              </Link>
              {/* <Link href="#" underline="none" color="inherit">
                Terms & Conditions
              </Link>
              <Link href="#" underline="none" color="inherit">
                Cookie Policy
              </Link> */}
            </Stack>
          </Grid>

          {/* Security */}
          {/* <Grid item xs={12} sm={6} md={2}>
            <Typography variant="h6" gutterBottom sx={{ color: "#333" }}>
              Security
            </Typography>
            <Stack spacing={1}>
              <Link href="#" underline="none" color="inherit">
                FAQ's
              </Link>
              <Link href="#" underline="none" color="inherit">
                Privacy Policy
              </Link>
              <Link href="#" underline="none" color="inherit">
                Terms & Conditions
              </Link>
              <Link href="#" underline="none" color="inherit">
                Cookie Policy
              </Link>
            </Stack>
          </Grid> */}

          {/* Contact Us */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" gutterBottom sx={{ color: "#333" }}>
              Contact Us:
            </Typography>
            <Link
              href="mailto:contact@banxity.com"
              underline="none"
              color="inherit"
              sx={{ mb: 2, display: "block" }}
            >
              contact@banxity.com
            </Link>
            <Stack direction="row" spacing={1}>
              <Link href="https://x.com/banxity" target="_blank">
              <IconButton size="small" sx={{ color: "#333" }}>
                <TwitterIcon />
              </IconButton>
              </Link>
              <Link href="https://www.youtube.com/@banxity" target="_blank">
              <IconButton size="small" sx={{ color: "#333" }}>
                <YouTubeIcon />
              </IconButton>
              </Link>
              <Link href="https://www.linkedin.com/company/banxity/" target="_blank">
              <IconButton size="small" sx={{ color: "#333" }}>
                <LinkedInIcon />
              </IconButton>
              </Link>
              <Link href="https://www.instagram.com/banxity/" target="_blank">
              <IconButton size="small" sx={{ color: "#333" }}>
                <InstagramIcon />
              </IconButton>
              </Link>
            </Stack>
          </Grid>
        </Grid>

        {/* Copyright */}
        <Typography
          variant="body2"
          align="center"
          sx={{
            mt: 4,
            pt: 2,
            borderTop: "1px solid rgba(0, 0, 0, 0.1)",
            color: "#333",
          }}
        >
          © 2024 Banxity Holding. All Rights Reserved
        </Typography>
      </Container>
    </Box>
  );
}
