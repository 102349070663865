import React from "react";
import { Box, Typography, Grid, Container } from "@mui/material";
import GroupImg from "../../assets/IMG2.png";
import styled from "@emotion/styled";

const Section6 = () => {
  return (
    <Container maxWidth="xl">
      <Grid
        container
        sx={{
          height: {
            xs: "100%",
            md: "50vh",
          },
          padding: {
            xs: "50px 20px 0 20px",
            md: "0 20px",
          },
          borderRight: {
            xs: "0px solid #14C79A",
            md: "25px solid #14C79A",
          },
          marginTop: {
            xs: "10px",
            md: "20px"
          }
        }}
      >
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            display: "flex",
            alignItems: "center",
            paddingLeft: "20px",
            position: {
              xs: "none",
              md: "relative",
            },
          }}
        >
          <Box
            sx={{
              width: "90%",
              position: {
                xs: "none",
                md: "relative",
              },
              top: "20%",
              left: "10%",

              zIndex: 1,
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                color: "black",
                marginBottom: "10px",
                zIndex: 1,
                color: "#013180",
                fontSize: "1.2rem",
                fontFamily: "aria",
              }}
            >
              Our Mission
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: "1.35rem",
                color: "black",
                lineHeight: "1.5",
                zIndex: 1,
                fontWeight: 100,
                color: "#000000",
                width: "90%",
              }}
            >
              To empower individuals and organizations
              by seamlessly integrating technology with 
              sustainable financial solutions, green investments, 
              and transparent development in real estate and agriculture. 
              We focus on fostering inclusive growth and smart cities, 
              creating a future where prosperity and environmental stewardship coexist.

            </Typography>
            {/* <Typography
              sx={{
                color: "black",
                margin: {
                  xs: "10px 0 20px 0",
                  md: "10px 0",
                },
                fontWeight: "bold",
              }}
            >
              Discover Our Story
            </Typography> */}
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={7}
          sx={{
            p: {
              xs: "0 0 10px 0",
              md: 0,
            },
          }}
        >
          <Box
            sx={{
              backgroundImage: `url(${GroupImg})`,
              backgroundSize: "cover",
              backgroundPosition: "center right",
              height: {
                xs: "30vh",
                md: "100%",
              },
              position: {
                xs: "none",
                md: "relative",
              },
              right: "20%",
            }}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          p: "50px 50px 0px 50px ",
        }}
      >
        <Grid
          container
          sx={{
            display: {
              xs: "none",
              md: "flex",
            },
            justifyContent: "space-between",
          }}
        >
          <Grid
            item
            xs={5}
            sx={{
              borderTop: "3px solid #14C79A",
            }}
          ></Grid>
          <Grid
            item
            xs={6}
            sx={{
              borderTop: "3px solid #14C79A",
            }}
          ></Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Section6;
