import React from "react";
import Section1 from "./section/section1";
import Section2 from "./section/section2";
import Section3 from "./section/section3";
import Section4 from "./section/Section4";
import Header from "../layout/header";
import Footer from "../layout/footer";
import Section9 from "./section/Section9";
import Section5 from "./section/Section5";
import Section6 from "./section/Section6";
import Section7 from "./section/Section7";
import Section8 from "./section/Section8";
import Section10 from "./section/Section10";

const index = () => {
  return (
    <div
      style={{
        backgroundColor: "#F7F8FF",
      }}
    >
      <Header />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Section7 />
      <Section8 />
      <Section9 />
      <Section10 />
      <Footer />
    </div>
  );
};

export default index;
