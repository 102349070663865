import React from "react";
import { Box, Typography } from "@mui/material";
import BgImage from "../../assets/Rectangle.png";
import styled from "@emotion/styled";

const Section2 = () => {
  const HighlightBox = styled(Box)({
    position: "relative",
    padding: "0 10px",
    borderRadius: "5px",
    display: "inline-block",
    zIndex: 1,
    color: "#013180",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "#D0F8CE",
      transform: "rotate(-2deg)",
      zIndex: -1,
    },
  });

  return (
    <Box
      sx={{
        position: "relative",
        height: "50%",
        width: "100%",
      }}
    >
      <Box
        sx={{
          m: "100px 0 0 0",
          height: "30vh",
          width: "60%",
          backgroundImage: `url(${BgImage})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          rotate: "-1deg",
        }}
      ></Box>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
          width: {
            xs: "100%",
            md: "42%",
          },
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
            fontSize: {
              xs: "1.6rem",
              md: "2rem",
            },
            fontWeight: 550,
            color: "#013180",
            fontFamily: "ari",
          }}
        >
          Designed to stand the{" "}
          <HighlightBox component="span">test of time and thrive </HighlightBox>
          in the face of challenges with resilience.
        </Typography>
      </Box>
    </Box>
  );
};

export default Section2;
