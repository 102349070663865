import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  CardMedia,
  Container,
  Grid,
  Link,
  List,
  ListItem,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import logo from "../../assets/Logo.png";
import MenuIcon from "@mui/icons-material/Menu";

const Header = () => {
  const [isMenuVisible, setIsMenuVisible] = useState(true);
  const [isDropdownVisible, setIsDropdownVisible] = useState(true);
  const [isVisible, setIsVisible] = useState(true);

  // MUi
  const [showBox, setShowBox] = useState(false);
  const [menuBox, setMenuBox] = useState(false);
  const [mobilePersonalLoanDropdown, setMobilePersonalLoanDropdown] =
    useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 10000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const toggleElement = (element) => {
    if (window.innerWidth <= 991) {
      if (element === "menu") {
        setIsMenuVisible(!isMenuVisible);
      } else if (element === "dropdown") {
        setIsDropdownVisible(!isDropdownVisible);
      }
    }
  };

  const handleClick = () => {
    setIsMenuVisible(!isMenuVisible);
    setIsDropdownVisible(true);
  };
  const isPathAvailable = window.location.href.includes("/get-registered");

  /// Mui
  const handleLogoClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const [activePage, setActivePage] = useState("");

  // Function to set active page
  const setActive = (pageName) => {
    setActivePage(pageName);
  };

  useEffect(() => {
    const handleScroll = () => {
      setMenuBox(false);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <>
        <Box>
          <AppBar
            sx={{
              boxShadow: "none",
              backgroundColor: "transparent",
              padding: {
                lg: "20px 0",
                xs: "10px 0",
              },
            }}
          >
            <Container maxWidth="xl" sx={{ padding: { md: "0 4.3%" } }}>
              <Toolbar sx={{ paddingX: { xs: 0 } }}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                  paddingX={{ xs: "20px", sm: "24px", md: "0px" }}
                >
                  <CardMedia
                    component="img"
                    sx={{
                      width: { xs: "120px", sm: "170px", md: "150px" },
                      cursor: "pointer",
                      bgcolor: "#D0F8CECC",
                      p: "10px 20px",
                    }}
                    src={logo}
                    onClick={() => {
                      handleLogoClick();
                      setActivePage("");
                    }}
                  />
                  <Stack
                    direction="row"
                    spacing={6}
                    sx={{
                      display: { xs: "none", sm: "none", md: "flex" },
                      cursor: "pointer",
                      bgcolor: "#D0F8CECC",
                      p: "10px 20px",
                    }}
                  >
                    <Link
                      underline="none"
                      fontWeight={activePage === "AlterHome" ? "800" : "500"}
                      fontSize="16"
                      fontFamily="Inter, sans-serif"
                      sx={{
                        color: activePage === "AlterHome" ? "#D2F9DF" : "black",
                      }}
                      onClick={() => {
                        setIsMenuVisible(!isMenuVisible);
                        handleLogoClick();
                      }}
                    >
                      About Us
                    </Link>
                    <Link
                      underline="none"
                      color="white"
                      fontWeight={activePage === "AlterHome" ? "800" : "500"}
                      fontSize="16"
                      fontFamily="Inter, sans-serif"
                      sx={{
                        color: activePage === "AlterHome" ? "#D2F9DF" : "black",
                      }}
                      onClick={() => {
                        setIsMenuVisible(!isMenuVisible);
                        handleLogoClick();
                      }}
                    >
                      Portfolio
                    </Link>
                    <Link
                      underline="none"
                      color="white"
                      fontWeight={activePage === "AlterHome" ? "800" : "500"}
                      fontSize="16"
                      fontFamily="Inter, sans-serif"
                      sx={{
                        color: activePage === "AlterHome" ? "#D2F9DF" : "black",
                      }}
                      onClick={() => {
                        setIsMenuVisible(!isMenuVisible);
                        handleLogoClick();
                      }}
                    >
                      Our team
                    </Link>
                    <Link
                      underline="none"
                      color="white"
                      fontWeight={activePage === "AlterHome" ? "800" : "500"}
                      fontSize="16"
                      fontFamily="Inter, sans-serif"
                      sx={{
                        color: activePage === "AlterHome" ? "#D2F9DF" : "black",
                      }}
                      onClick={() => {
                        setIsMenuVisible(!isMenuVisible);
                        handleLogoClick();
                      }}
                    >
                      Careers
                    </Link>
                    <Link
                      underline="none"
                      color="white"
                      fontWeight={activePage === "AlterHome" ? "800" : "500"}
                      fontSize="16"
                      fontFamily="Inter, sans-serif"
                      sx={{
                        color: activePage === "AlterHome" ? "#D2F9DF" : "black",
                      }}
                      onClick={() => {
                        setIsMenuVisible(!isMenuVisible);
                        handleLogoClick();
                      }}
                    >
                      Contact
                    </Link>
                  </Stack>
                  <Box
                    sx={{
                      display: { xs: "flex", md: "none" },
                      height: { xs: "33px", sm: "36px" },
                      width: { xs: "38px", sm: "42px" },
                      borderRadius: "2px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <MenuIcon
                      fontSize="large"
                      sx={{ color: "black" }}
                      onClick={() => setMenuBox(!menuBox)}
                    />
                  </Box>
                </Box>
              </Toolbar>
            </Container>
          </AppBar>
        </Box>
      </>
      {menuBox && (
        <Box
          sx={{
            position: "fixed ",
            zIndex: "90",
            width: "100%",
            height: "auto",
            bgcolor: "#D0F8CE",
            marginTop: { xs: "0px", sm: "56px" },
            display: { xs: "flex", md: "none" },
            maxHeight: "calc(100vh - 64px)",
            overflowY: "auto",
          }}
        >
          <List
            sx={{
              width: "100%",
            }}
          >
            <ListItem
              sx={{
                borderBottom: "1px solid grey",
                paddingY: "15px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "40px",
              }}
              onClick={() => {
                setMenuBox(!menuBox);
                handleLogoClick();
              }}
            >
              <Link
                style={{
                  color: "#fff",
                  textDecoration: "none",
                  cursor: "pointer",
                  textTransform: "capitalize",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "17px",
                    color: "black",
                  }}
                >
                  about us
                </Typography>
              </Link>
            </ListItem>

            <ListItem
              sx={{
                borderBottom: "1px solid grey",
                paddingY: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "1vh",
              }}
              onClick={() => {
                setMenuBox(!menuBox);
              }}
            >
              <Link
                style={{
                  color: "#fff",
                  textDecoration: "none",
                  cursor: "pointer",
                  textTransform: "capitalize",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "17px",
                    color: "black",
                  }}
                >
                  Portfolio
                </Typography>
              </Link>
            </ListItem>

            <ListItem
              sx={{
                borderBottom: "1px solid grey",
                paddingY: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "1vh",
              }}
              onClick={() => {
                setMenuBox(!menuBox);
              }}
            >
              <Link
                style={{
                  color: "#fff",
                  textDecoration: "none",
                  cursor: "pointer",
                  textTransform: "capitalize",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "17px",
                    color: "black",
                  }}
                >
                  Our team
                </Typography>
              </Link>
            </ListItem>

            <ListItem
              sx={{
                borderBottom: "1px solid grey",
                paddingY: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "1vh",
              }}
              onClick={() => {
                setMenuBox(!menuBox);
              }}
            >
              <Link
                style={{
                  color: "#fff",
                  textDecoration: "none",
                  cursor: "pointer",
                  textTransform: "capitalize",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "17px",
                    color: "black",
                  }}
                >
                  Careers
                </Typography>
              </Link>
            </ListItem>

            <ListItem
              sx={{
                borderBottom: "1px solid grey",
                paddingY: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "1vh",
              }}
              onClick={() => {
                setMenuBox(!menuBox);
              }}
            >
              <Link
                style={{
                  color: "#fff",
                  textDecoration: "none",
                  cursor: "pointer",
                  textTransform: "capitalize",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "17px",
                    color: "black",
                  }}
                >
                  Contact
                </Typography>
              </Link>
            </ListItem>
          </List>
        </Box>
      )}
    </>
  );
};

export default Header;
